import React from 'react';
import ReactDOM from 'react-dom';
import {
  connect,
  MapDispatchToProps,
  MapStateToProps,
} from 'react-redux';
import {
  AnyAction,
  Dispatch,
} from 'redux';
import {
  postMessageToMergeWorker,
} from '../../getConfiguredStore';
import {
  ProductClass,
  TradeFlow,
  DataFacet,
  QueryLevel
} from '../../graphQL/types/shared';
import {
  showDataNotes,
  showExports,
  showShare,
} from '../../overlay/actions';
import resizeMeasurementListener, {
  IListener,
} from '../../ResizeMeasurementListener';
import {
  IRootState,
} from '../../rootState';
import {
  DataIssueType,
} from '../../sharedComponents/dataNotes/Utils';
import ExploreGraphTitle from '../../sharedComponents/ExploreGraphTitle';
import ErrorOverlay from '../../sharedComponents/GraphError';
import Loading from '../../sharedComponents/GraphLoading';
import Share, {
  DataNotesProps,
  TutorialModalProps,
} from '../../sharedComponents/newGraphShare';
import TriggerDataDownload from '../../sharedComponents/newGraphShare/TriggerDataDownload';
import RadioSelector from '../../sharedComponents/radioSelector';
import YearSelector, {
  TimelineType,
} from '../../sharedComponents/timeline';
import {
  fetchIfNeeded as fetchCountryMetadataIfNeeded,
  getDataSelector as getMainThreadCountryMetadataSelector,
} from '../../sharedData/newCountryMetadata';
import {
  fetchIfNeeded as fetchGeoJSONIfNeeded,
} from '../../sharedData/newWorldGeoJSON';
/* Location aggregation groups-related fetches */
import {
  fetchIfNeeded as fetchGroupsMetadataIfNeeded,
  getDataSelector as getMainThreadGroupsMetadataSelector
} from '../../sharedData/groupsMetadata';
import {
  fetchIfNeeded as fetchGroupMembersMetadataIfNeeded,
  getDataSelector as getMainThreadGroupMembersMetadataSelector
} from '../../sharedData/groupMembersMetadata';


import {
  getDataSelector as getGeoJSONSelector,
  IData as IGeoJSONData,
} from '../../sharedData/newWorldGeoJSON';
import GraphTotal from '../../tree/graphTotal/GraphTotal';
import {
  DataSource,
  ILoadable,
  LoadableStatus,
  Target,
} from '../../Utils';
import ChartShownTitle from '../../viz/ChartShownTitle';
import determineNewGraphStatus from '../../viz/determineNewGraphStatus';
import {
  GraphStatus as GraphStatusBase,
  GraphStatusCode,
  IGraphDispatchProps,
  productClassChoices,
  tradeFlowChoices,
  UpdateType,
  VizType,
} from '../../viz/Utils';
import {
  ChartContainerNoSectors as ChartContainer,
  ChartHeader,
  SpinnerContainer,
  TooltipsContainer,
  YearSelectorContainerWithoutPlayButton,
} from '../../viz/VizGrid';
import { vizSettingsPortalId } from '../../viz/VizSettings';
import {
  fetchIfNeeded as fetch_CCPY_CP_IfNeeded,
} from '../../workerStore/fetchedData/countryCountryProductYearByCountryProduct';
import {
  fetchIfNeeded as fetch_CCY_C_IfNeeded,
} from '../../workerStore/fetchedData/countryCountryYearByCountry';
import {
  fetchIfNeeded as fetchLevelMappingIfNeeded,
} from '../../workerStore/fetchedData/countryLevelMapping';
import {
  fetchIfNeeded as fetchMetadataIfNeeded,
  IMetadatum as ICountryMetadatum,
} from '../../workerStore/fetchedData/countryMetadata';
import {
  fetchIfNeeded as fetch_CPY_P_IfNeeded,
} from '../../workerStore/fetchedData/countryProductYearByProduct';
import {
  checkForInvalidRoutingInput,
  getHashInputFromRoutingAndUIState,
  IHasError,
  IInputFromURLRouting,
  IUIState,
  MergedData,
} from '../../workerStore/geo/getReducer';
import {
  ErrorCode,
  IComputationOutput,
} from '../../workerStore/geo/Utils';
import {
  RoutingInputCheckResult,
} from '../../workerStore/newGraphDataCache';
import Chart from '../chart';
import {
  getMergedData,
  getUIState,
  getUpdateType,
  reset,
  startSubscribing,
  stopSubscribing,
  updateInputFromURLRouting,
  updateUIState,
} from '../reducer';
import DetailOverlayContainer from './DetailOverlayContainer';
import Legend from './legend';
import Tooltip from './Tooltip';
import renderNewGraphTitle, {NewGraphTitle} from '../../viz/NewChartTitle';
import { determineDataFacet } from '../../graphQL/Utils';

const getNextGraphStatus = (
    nextComputedData: MergedData, prevGraphStatus: GraphStatus): GraphStatus => {

  let preliminaryGraphStatus: GraphStatus;
  if (nextComputedData.hasError === true) {
    preliminaryGraphStatus = {
      status: GraphStatusCode.Fail,
      value: nextComputedData.errorCode,
      loadableStatus: LoadableStatus.NotPresent,
    };
  } else {

    preliminaryGraphStatus = determineNewGraphStatus<IComputationOutput, ErrorCode>(
      ErrorCode.NoData, prevGraphStatus, nextComputedData.value,
    );
  }

  // If there's data for no country, on the map, this means there's no data to
  // show:
  let finalGraphStatus: GraphStatus;
  if (preliminaryGraphStatus.status === GraphStatusCode.Success) {
    const {value: {countryData}} = preliminaryGraphStatus;
    const nonPrimaryCounries = Object.values(countryData).filter(
      ({isPrimaryCountry}) => isPrimaryCountry === false,
    );
    // If there's data for no other countries beyond the primary country,
    // there's actually no data to show on the map:
    const hasNoValidData = (nonPrimaryCounries.length === 0);
    finalGraphStatus = hasNoValidData ? {
      status: GraphStatusCode.Fail,
      value: ErrorCode.NoData,
      loadableStatus: LoadableStatus.Present,
    } : preliminaryGraphStatus;
  } else {
    finalGraphStatus = preliminaryGraphStatus;
  }
  return finalGraphStatus;
};
const resetCertainStatesWhenChangingUIControl =
  (nextProps: IProps) =>
    (prevState: IState): IState => {

  let detailedCountry: number | undefined;
  let hovered: ITooltipInfo | undefined;

  if (nextProps.routingInputValidity.isValid === true &&
      prevState.mirrored.routingInputValidity.isValid === true) {

    const needToResetMouseInteraction =
      (nextProps.productClass !== prevState.mirrored.productClass) ||
      (nextProps.country !== prevState.mirrored.country) ||
      (nextProps.queryLevel !== prevState.mirrored.queryLevel) || 
      (nextProps.product !== prevState.mirrored.product) ||
      (nextProps.mergedData.hasError !== prevState.mirrored.mergedData.hasError);

    if (needToResetMouseInteraction === true) {
      detailedCountry = undefined;
      hovered = undefined;
    } else {
      detailedCountry = prevState.detailedCountry;
      hovered = prevState.hovered;
    }

  } else {
    detailedCountry = undefined;
    hovered = undefined;
  }

  return {
    ...prevState,
    detailedCountry, hovered,
  };

};

const getStateUpdater = (
    // Note: `unlaggedStateTransformer` is the state transformer function
    // usually passed into `setState` where we perform any change in "unlagged
    // state" here e.g. set IDs of highlighted products or products with
    // detailed overlay:
    unlaggedStateTransformer: (prevState: IUnlaggedState) => IUnlaggedState,
    // Default to `this.props` because most of the time, `updateState` is used
    // in place of `setState` and as such, `nextProps` haven't changed.
    nextProps: IProps,
  ) =>
    (prevState: IState): IState => {

  const newUnlaggedState = unlaggedStateTransformer(prevState);

  const graphStatus = getNextGraphStatus(nextProps.mergedData, prevState.graphStatus);
  const newState = {...newUnlaggedState, graphStatus};
  return newState as IState;
};

//#region Type definitions
export interface ITooltipInfo {
  id: number;
  x: number;
  y: number;
}
export interface IOwnProps extends IInputFromURLRouting {
  onYearChange: (year: number) => void;
  onProductClassChange: (productClass: ProductClass) => void;
  onTradeFlowChange: (tradeFlow: TradeFlow) => void;
}

interface IStateProps extends IUIState {
  mergedData: MergedData;
  updateType: UpdateType;
  routingInputValidity: RoutingInputCheckResult<IHasError, DataSource>;
  geoJSONData: ILoadable<IGeoJSONData>;
  countryMetadata: ILoadable<Map<number, ICountryMetadatum>>;
}

type IProps = IOwnProps & IStateProps & IGraphDispatchProps & TutorialModalProps;

interface IUnlaggedState {
  // Country with hover tooltip:
  hovered: ITooltipInfo | undefined;
  // Country with detail overlay:
  detailedCountry: number | undefined;

  mirrored: {
    country: IProps['country'];
    product: IProps['product'];
    year: IProps['year'];
    productClass: IProps['productClass'];
    tradeDirection: IProps['tradeDirection'];
    tradeFlow: IProps['tradeFlow'];
    mergedData: IProps['mergedData'];
    routingInputValidity: IProps['routingInputValidity']
  };
}

export type GraphStatus = GraphStatusBase<IComputationOutput, ErrorCode>;

interface IState extends IUnlaggedState {
  graphStatus: GraphStatus;
  showDataDownload: boolean;
  graphTitle: string;
}
//#endregion
class Geo extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const unlaggedInitialState: IUnlaggedState = {
      hovered: undefined,
      detailedCountry: undefined,
      mirrored: {
        country: props.country,
        queryLevel: props.queryLevel,
        product: props.product,
        year: props.year,
        productClass: props.productClass,
        tradeDirection: props.tradeDirection,
        tradeFlow: props.tradeFlow,
        mergedData: props.mergedData,
        routingInputValidity: props.routingInputValidity,
      },
    };

    const graphStatus = getNextGraphStatus(props.mergedData, {
      status: GraphStatusCode.Initial,
      loadableStatus: LoadableStatus.Initial,
    });

    this.state = {
      ...unlaggedInitialState,
      graphStatus,
      showDataDownload: false,
      graphTitle: '',
      hovered: undefined
    };
  }

  //#region setState-related methods:
  setState() {
    throw new Error('Do not call `setState` directly in this component. Use `updateState` instead.');
  }

  private updateState(
      // Note: `unlaggedStateTransformer` is the state transformer function
      // usually passed into `setState` where we perform any change in "unlagged
      // state" here e.g. set IDs of highlighted products or products with
      // detailed overlay:
      unlaggedStateTransformer: (prevState: IUnlaggedState) => IUnlaggedState,
      // Default to `this.props` because most of the time, `updateState` is used
      // in place of `setState` and as such, `nextProps` haven't changed.
      nextProps: IProps = this.props): void {

    // Note: have to call `setState` on `super` because we disable `setState` on
    // this instance;
    super.setState(getStateUpdater(unlaggedStateTransformer, nextProps));
  }
  //#endregion

  //#region Data-fetching methods
  private fetchData(props: IProps) {
    const {
      productClass, dispatchToWorkerStore, dispatchToBothStores, country, queryLevel, product, dispatchToMainStore,
    } = props;
    dispatchToWorkerStore(fetchLevelMappingIfNeeded({}));
    dispatchToBothStores(fetchMetadataIfNeeded({}));
    dispatchToMainStore(fetchGeoJSONIfNeeded({}));
    // Adding location aggregation groups-related fetches
    dispatchToBothStores(fetchGroupsMetadataIfNeeded({}));
    dispatchToBothStores(fetchGroupMembersMetadataIfNeeded({}));

    if (country !== undefined && product === undefined) {
      dispatchToWorkerStore(fetch_CCY_C_IfNeeded({country, queryLevel}));
    } else if (country === undefined && product !== undefined) {
      dispatchToWorkerStore(fetch_CPY_P_IfNeeded({productClass, product}));
    } else if (country !== undefined && product !== undefined) {
      dispatchToWorkerStore(fetch_CCPY_CP_IfNeeded({productClass, product, country, queryLevel}));
    }
  }

  //#endregion

  //#region Lifecycle methods
  componentDidMount() {
    // Register "resizer" method:
    resizeMeasurementListener.addListener(this.resizeListener);

    const {
      dispatchToWorkerStore,
      productClass, year, country, queryLevel,
      tradeDirection, tradeFlow, product,
      dispatchToBothStores, dispatchToMainStore,
    } = this.props;
    dispatchToWorkerStore(startSubscribing());
    dispatchToBothStores(updateInputFromURLRouting({
      productClass, year, country, queryLevel,
      tradeDirection, tradeFlow, product,
    }, UpdateType.Hard));
    dispatchToMainStore(fetchCountryMetadataIfNeeded({}));
    this.fetchData(this.props);
  }
  componentWillUnmount() {
    const {dispatchToBothStores, dispatchToWorkerStore} = this.props;
    // Clean up the UI state and derived data in the redux store:
    // and stop computing derived data:
    dispatchToBothStores(reset());
    dispatchToWorkerStore(stopSubscribing());
    // Unregister "resizer" method:
    resizeMeasurementListener.removeListener(this.resizeListener);
  }

  static getDerivedStateFromProps(nextProps: IProps, prevState: IState): IState {
    const mirrored = {
      country: nextProps.country,
      queryLevel: nextProps.queryLevel,
      product: nextProps.product,
      year: nextProps.year,
      productClass: nextProps.productClass,
      tradeDirection: nextProps.tradeDirection,
      tradeFlow: nextProps.tradeFlow,
      mergedData: nextProps.mergedData,
      routingInputValidity: nextProps.routingInputValidity,
    };

    const updatedState = getStateUpdater(
      resetCertainStatesWhenChangingUIControl(nextProps),
      nextProps,
    )(prevState);

    return {
      ...updatedState,
      mirrored,
    };
  }

  componentDidUpdate(_unused: IProps, prevState: IState) {
    const nextProps = this.props;
    if (nextProps !== prevState.mirrored) {
      if (nextProps.country !== prevState.mirrored.country ||
          nextProps.queryLevel !== prevState.mirrored.queryLevel ||
          nextProps.product !== prevState.mirrored.product ||
          nextProps.year !== prevState.mirrored.year ||
          nextProps.productClass !== prevState.mirrored.productClass ||
          nextProps.tradeDirection !== prevState.mirrored.tradeDirection ||
          nextProps.tradeFlow !== prevState.mirrored.tradeFlow) {

        this.fetchData(nextProps);
      }
      // Trigger computation when props from routing changes:
      const {
        country, queryLevel, year, productClass, tradeDirection, tradeFlow,
        product,
        dispatchToBothStores,
      } = nextProps;

      if (nextProps.country !== prevState.mirrored.country ||
          // nextProps.queryLevel !== prevState.mirrored.queryLevel ||
          nextProps.productClass !== prevState.mirrored.productClass ||
          nextProps.product !== prevState.mirrored.product) {

        dispatchToBothStores(updateInputFromURLRouting({
          productClass, year, country, queryLevel,
          tradeDirection, tradeFlow, product,
        }, UpdateType.Hard));
      } else if (nextProps.year !== prevState.mirrored.year ||
                  nextProps.tradeDirection !== prevState.mirrored.tradeDirection ||
                  nextProps.tradeFlow !== prevState.mirrored.tradeFlow) {

        dispatchToBothStores(updateInputFromURLRouting({
          productClass, year, country, queryLevel,
          tradeDirection, tradeFlow, product,
        }, UpdateType.Soft));
      }

    }
  }
  //#endregion

  //#region size measurement methods
  private chartContainerEl: HTMLElement | null = null;
  private rememberChartContainer = (el: HTMLElement | null) => {
    this.chartContainerEl = el;
    if (el) {
      this.measureDOMLayout();
    }
  }
  private chartRootEl: HTMLElement | null = null;
  private rememberChartRootEl = (el: HTMLElement | null) => this.chartRootEl = el;

  private measureDOMLayout() {
    if (this.chartContainerEl !== null) {
      const {
        width: decimalWidth,
        height: decimalHeight,
      } = this.chartContainerEl.getBoundingClientRect();

      this.props.dispatchToBothStores(updateUIState({
        width: Math.floor(decimalWidth),
        height: Math.floor(decimalHeight),
      }, UpdateType.Hard));
    }
  }
  private resizeListener: IListener = this.getResizeListener();
  private getResizeListener(): IListener {
    let decimalWidth: number;
    let decimalHeight: number;
    let originalPositioning: string;

    // Element that we need to remove from regular DOM layout flow so as to allow the
    // chart container to "relax" to its "natural" width:
    let target: HTMLElement | null;

    const before = () => {
      target = this.chartRootEl;
      if (target !== null) {
        // Remember the original value of `position` and `height` so that we can restore them later:
        const computedStyle = window.getComputedStyle(target);
        originalPositioning = computedStyle.position!;
        if (originalPositioning !== null) {
          // ... then take the graph's root elemen out of the flow by setting its `position`
          // to `absolute`. This allows `this.chartContainerEl` to resize to is "natural"
          // width...
          target.style.position = 'absolute';
        }
      }
    };

    const measure = () => {
      const el = this.chartContainerEl;
      if (el !== null) {
        // ... which we measure (excluding the height)...
        const {width, height} = el.getBoundingClientRect();
        decimalWidth = width;
        decimalHeight = height;

      }
    };

    const after = () => {
      target = this.chartRootEl;
      if (target !== null) {
        // ... then restore the `position` and `height` to their original values:
        target.style.position = originalPositioning;
      }

      this.props.dispatchToBothStores(updateUIState({
        width: Math.floor(decimalWidth),
        height: Math.floor(decimalHeight),
      }, UpdateType.Hard));
    };

    return {
      before,
      measure,
      after,
    };
  }
  //#endregion

  //#region UI state update methods
  private hideTooltip = () => this.updateState(
    prevState => ({...prevState, hovered: undefined}),
  )

  private showTooltip = (info: ITooltipInfo) => this.updateState(
    prevState => ({...prevState, hovered: info}),
  )
  private showDetailOverlay = (detailedCountry: number) => this.updateState(
    prevState => ({...prevState, detailedCountry}),
  )

  private hideDetailOverlay = () => this.updateState(
    prevState => ({...prevState, detailedCountry: undefined}),
  )
  //#endregion

  //#region Exports-related methods
  private onShareClick = () => this.props.dispatchToMainStore(showShare(VizType.Geo));
  private onExportsClick = () => this.props.dispatchToMainStore(showExports(VizType.Geo, this.state.graphTitle));
  private onDataDownloadClick = () => {
    this.updateState(
      (state: IState): IState => ({...state, showDataDownload: true}),
    );
  }
  private dismissDataDownload = () => {
    this.updateState(
      (state: IState): IState => ({...state, showDataDownload: false}),
    );
  }
  //#endregion

  private updateGraphTitle = (val: string) => this.updateState(
    prevState => ({...prevState, graphTitle: val}),
  )

  render() {
    const props = this.props;
    const {
      onProductClassChange, onTradeFlowChange,
      updateType, width, height, geoJSONData,
      onYearChange, dispatchToMainStore, countryMetadata,
    } = props;
    const {
      graphStatus, hovered, detailedCountry,
      mirrored: {
        year, productClass, tradeFlow, country, product, queryLevel
      },
    } = this.state;

    const dataNotes: DataIssueType[] = [];
    let isDataNotesWarningActive: boolean;
    if (countryMetadata.status !== LoadableStatus.Present) {
      isDataNotesWarningActive = false;
    } else {
      if ((queryLevel !== QueryLevel.Location) || country === undefined && product === undefined) {
        isDataNotesWarningActive = false;
      } else {
        let showCountryNotes: boolean;
        if (country === undefined || isNaN(country)) {
          showCountryNotes = false;
        } else {
          const {data} = countryMetadata;
          const retrievedMetadatum = data.get(country);
          if (retrievedMetadatum === undefined) {
            throw new Error('Cannot find metadatum for country' + country);
          }

          showCountryNotes = !retrievedMetadatum.is_trusted;
        }
        dataNotes.push(DataIssueType.ServicesInGeneral);
        if (showCountryNotes === true) {
          dataNotes.push(DataIssueType.UnreliableCountry);
        }
        isDataNotesWarningActive = true;
      }
    }
    const dataNotesProps: DataNotesProps = {
      isDataNotesWarningActive,
      launchDataNotes: () => dispatchToMainStore(
        showDataNotes(dataNotes),
      ),
    };

    //#region actual map:
    let geoMap: JSX.Element | null;
    let share: JSX.Element | null;
    let dataDownload: JSX.Element | null;
    let newChartTitle: JSX.Element | null;

    if ((graphStatus.status === GraphStatusCode.Success ||
        graphStatus.status === GraphStatusCode.LoadingAfterSuccess) &&
        geoJSONData.status === LoadableStatus.Present) {

      const {value: {countryData}} = graphStatus;

      geoMap = (
        <Chart saveRootEl={this.rememberChartRootEl}
          hideTooltip={this.hideTooltip}
          showTooltip={this.showTooltip}
          showDetailOverlay={this.showDetailOverlay}
          geoJSONData={geoJSONData.data}
          queryLevel={this.state.mirrored.queryLevel}
          width={width} height={height} countryData={countryData}
          hovered={this.state.hovered}
        />
      );
      share = (
        <Share
          launchShare={this.onShareClick}
          launchExports={this.onExportsClick}
          showDataDownload={this.onDataDownloadClick}
          dataNotesProps={dataNotesProps}
          isTutorialModalOpen={this.props.isTutorialModalOpen}
          setIsTutorialModalOpen={this.props.setIsTutorialModalOpen}
          closeDetailOverlay={this.hideDetailOverlay}
        />
      );

      if (this.state.showDataDownload
          && this.props.mergedData.hasError === false
          && this.props.mergedData.value.status === LoadableStatus.Present
        ) {
        const csvData: object[] = [];
        Object.keys(countryData).forEach((key) => {
          const {shortLabel: Name, detailOverlayInfo, isPrimaryCountry} = countryData[key];
          const dataRows: {[label: string]: any} = {};
          detailOverlayInfo.forEach(({label, value}) => {
              if (typeof value === 'string' || typeof value === 'number') {
                dataRows[label] = value;
              }
            });
          if (!isPrimaryCountry) {
              csvData.push({Name, ...dataRows});
            }
        });
        const title = this.state.graphTitle.length ? this.state.graphTitle : 'data';
        dataDownload = (
          <TriggerDataDownload
            data={csvData}
            graphTitle={title}
            closeOverlay={this.dismissDataDownload}
          />
        );
      } else {
        dataDownload = null;
      }
    } else {
      geoMap = null;
      share = (
        <Share
          launchShare={null}
          launchExports={null}
          showDataDownload={null}
          dataNotesProps={dataNotesProps}
          isTutorialModalOpen={this.props.isTutorialModalOpen}
          setIsTutorialModalOpen={this.props.setIsTutorialModalOpen}
          closeDetailOverlay={this.hideDetailOverlay}
        />
      );
      dataDownload = null;
    }
    //#endregion

    //#region Total
    let totalElem: JSX.Element | null;
    const totalServices = product === undefined ? null : undefined;
    if (graphStatus.status === GraphStatusCode.Success ||
        graphStatus.status === GraphStatusCode.LoadingAfterSuccess) {

      const {value: {total}} = graphStatus;
      totalElem = (
        <GraphTotal
          numerator={total}
          denominator={undefined}
          totalGoods={total}
          totalServices={totalServices}
          hiddenCategories={[]}
          selectedCategories={[]}
          queryLevel={queryLevel}
        />
      );
    } else {
      totalElem = (
        <GraphTotal
          numerator={0}
          denominator={undefined}
          totalGoods={0}
          totalServices={totalServices}
          hiddenCategories={[]}
          selectedCategories={[]}
        />
      );
    }
    //#endregion

    let facet = determineDataFacet({location: country, product, target: Target.Product, partner: undefined, vizType: VizType.Geo});


    // newChartTitle = renderNewGraphTitle(VizType.Tree, facet, chartTitleInputParameters, graphTotal);
    const titleInfo = {
      location: country,
      queryLevel: queryLevel,
      product: product,
      facet: facet,
    };

    //#region Product class selector
    const productClassSelector = (
      <RadioSelector
        tooltipText={__lexiconText('applicationWide.productClassSelector.tooltipText')}
        mainLabel={__lexiconText('applicationWide.productClassSelector.mainLabel')}
        choices={productClassChoices}
        selected={productClass}
        onClick={onProductClassChange}
      />
    );
    //#endregion

    //#region Trade flow selector:
    const tradeFlowSelector = (
      <RadioSelector
        tooltipText={__lexiconText('applicationWide.tradeFlowSelector.tooltipText')}
        mainLabel={__lexiconText('applicationWide.tradeFlowSelector.mainLabel')}
        choices={tradeFlowChoices}
        selected={tradeFlow}
        onClick={onTradeFlowChange}
      />
    );
    //#endregion

    //#region Loading spinner
    let loadingSpinner: JSX.Element | null;
    if (graphStatus.status === GraphStatusCode.Initial ||
        graphStatus.status === GraphStatusCode.LoadingAfterFailure ||
        graphStatus.status === GraphStatusCode.LoadingAfterSuccess) {

      if (updateType === UpdateType.Hard) {
        loadingSpinner = <Loading/>;
      } else {
        loadingSpinner = null;
      }
    } else {
      loadingSpinner = null;
    }
    //#endregion

    //#region Error overlay
    let errorOverlay: JSX.Element | null;
    if (graphStatus.status === GraphStatusCode.Fail) {
      const {value} = graphStatus;
      let message: string;
      if (value === ErrorCode.NoData) {
        message = __lexiconText('error.noData');
      } else {
        message = __lexiconText('error.pickTypeOfAggregationEntity');
      }
      errorOverlay = (
        <ErrorOverlay message={message}/>
      );
    } else {
      errorOverlay = null;
    }
    //#endregion

    //#region Hover tooltip
    let tooltip: JSX.Element | null;
    if (graphStatus.status === GraphStatusCode.Success && hovered !== undefined) {
      const {value: {countryData}} = graphStatus;

      const retrieved = countryData[hovered.id];
      if (retrieved === undefined) {
        tooltip = null;
      } else {
        if (retrieved.isPrimaryCountry === true) {
          tooltip = null;
        } else {
          const {shortLabel, tooltipInfo, regionName, regionColor} = retrieved;
          const {x, y} = hovered;
          tooltip = (
            <Tooltip country={shortLabel}
              x={x} y={y} tooltipInfo={tooltipInfo}
              regionName={regionName} regionColor={regionColor}
            />
          );
        }
      }
    } else {
      tooltip = null;
    }
    //#endregion

    //#region Year selector
    const yearSelector = (
      <YearSelector
        productClass={productClass} year={year}
        type={TimelineType.SingleYear}
        onYearChange={onYearChange}
        />
    );
    //#endregion

    //#region Detail overlay container
    let detailOverlay: JSX.Element | null;
    if ((graphStatus.status === GraphStatusCode.Success ||
        graphStatus.status === GraphStatusCode.LoadingAfterSuccess)) {

      const {value: {countryData}} = graphStatus;
      detailOverlay = (
        <DetailOverlayContainer key='detail-overlay'
          detailed={detailedCountry}
          countryData={countryData}
          hideOverlay={this.hideDetailOverlay}/>
      );
    } else {
      detailOverlay = null;
    }
    //#endregion

    //#region Legend
    let legendElem: JSX.Element | null;
    if (graphStatus.status === GraphStatusCode.Success ||
        graphStatus.status === GraphStatusCode.LoadingAfterSuccess) {
      const showSelectedCountry = (this.state.mirrored.country !== undefined);

      const {value: {legendData}} = graphStatus;
      legendElem = (
        <Legend {...legendData} showSelectedCountry={showSelectedCountry}/>
      );
    } else {
      legendElem = null;
    }

    //#endregion

    const vizSettingsNodeRef = document.querySelector<HTMLElement>(`#${vizSettingsPortalId}`);

    let vizSettings: React.ReactElement<any> | null;
    if (vizSettingsNodeRef) {
      vizSettings = ReactDOM.createPortal((
        <>
          {productClassSelector}
          {tradeFlowSelector}
        </>
      ), vizSettingsNodeRef);
    } else {
      vizSettings = null;
    }

    let chartTitleElem: JSX.Element | null = null;
    if(titleInfo) {
      chartTitleElem = 
        <NewGraphTitle
        country={titleInfo.location}
        queryLevel={titleInfo.queryLevel}
        product={titleInfo.product}
        year={year}
        tradeDirection={props.tradeDirection}
        productClass={props.productClass}
        // setTitle={() => setNewChartTitle}
        facet={titleInfo.facet as DataFacet}
        graphTotal={totalElem}
        setTitle={(val: string) => this.updateGraphTitle(val)}
      />
  
    }

    return (
      <>
      {chartTitleElem}
        {/* <ChartHeader>
          <ExploreGraphTitle
            country={country}
            queryLevel={queryLevel}
            product={product}
            year={year}
            tradeDirection={props.tradeDirection}
            productClass={props.productClass}
            setTitle={(val: string) => this.updateGraphTitle(val)}
          />
          <ChartShownTitle
            text={totalElem}
          />
        </ChartHeader> */}
        <ChartContainer ref={this.rememberChartContainer}>
          {geoMap}
          {legendElem}
        </ChartContainer>
        <TooltipsContainer>
          {tooltip}
        </TooltipsContainer>
        <SpinnerContainer>
          {loadingSpinner}
          {errorOverlay}
        </SpinnerContainer>
        <YearSelectorContainerWithoutPlayButton>
          {yearSelector}
        </YearSelectorContainerWithoutPlayButton>
        {detailOverlay}
        {vizSettings}
        {share}
        {dataDownload}
      </>
    );
  }
}

const mapStateToProps: () => MapStateToProps<IStateProps, IOwnProps, IRootState> = () => {
  const getGeoJSON = getGeoJSONSelector();
  const getCountryMetadata = getMainThreadCountryMetadataSelector();
  const getGroupsMetadata = getMainThreadGroupsMetadataSelector();
  const getGroupMembersMetadata = getMainThreadGroupMembersMetadataSelector();

  return (rootState: IRootState, ownProps: IOwnProps) => {

    const {country, queryLevel, product} = ownProps;

    const {width, height} = getUIState(rootState);
    const hashInput = getHashInputFromRoutingAndUIState(ownProps, getUIState(rootState));
    const mergedData = getMergedData(rootState, hashInput);
    const countryMetadata = getCountryMetadata(rootState, {});
    const groupsMetadata = getGroupsMetadata(rootState, {});
    const groupMembersMetadata = getGroupMembersMetadata(rootState, {});
    return {
      width, height,
      mergedData,
      updateType: getUpdateType(rootState),
      routingInputValidity: checkForInvalidRoutingInput(({country, product})),
      geoJSONData: getGeoJSON(rootState, {}),
      countryMetadata,
      queryLevel,
    };
  };
};

const mapDispatchToProps: MapDispatchToProps<IGraphDispatchProps, IOwnProps> =
  (dispatch: Dispatch) => {

    return {
      dispatchToWorkerStore: (action: AnyAction) => postMessageToMergeWorker(action),
      dispatchToMainStore: (action: AnyAction) => dispatch(action),
      dispatchToBothStores: (action: AnyAction) => {
        dispatch(action);
        postMessageToMergeWorker(action);
      },
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(Geo);

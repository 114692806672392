import React from 'react';
import styled from 'styled-components';
import {
  IDetailOverlayRow as IRow,
} from '../../viz/Utils';

import {
  getRows,
} from '../../sharedComponents/DetailOverlay';

// Move the tooltip above the mouse pointer by this amount to preven the mouse
// from constantly running into the tooltip when the mouse moves up:
const tooltipVerticalOffset = 10;

const tooltipBorderColor = 'rgba(255, 255, 255, 0.2)';
const tooltipFillColor = 'rgba(92, 100, 125, 0.9)';

// These are in `vw` units:
const tooltipWidth = 10;
const arrowHeight = tooltipWidth / 12;
const arrowWidth = arrowHeight;

// This is the root element for the highlight tooltip.
// Use a different `z-index` for hover tooltip:
export const Root = styled.div`
  position: absolute;
  width: ${tooltipWidth}vw;
  transform: translateX(-50%) translateY(-100%);
  background-color: ${tooltipFillColor};
  border: 1px solid ${tooltipBorderColor};

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: transparent;
    border-bottom: 0;
  }

  &::before {
    bottom: -${arrowHeight}vw;
    left: 50%;
    transform: translateX(-50%);
    border-top-color: ${tooltipBorderColor};
    border-width: ${arrowWidth}vw;
  }

  &::after {
    bottom: calc(-${arrowHeight}vw + 1px);
    left: 50%;
    transform: translateX(-50%);
    border-top-color: ${tooltipFillColor};
    border-width: calc(${arrowWidth}vw - 1px);
  }
`;
const ContentContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  color: rgba(255, 255, 255, 0.8);
`;

const RegionContainer = styled.div`
  text-transform: uppercase;
  height: 1rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.6rem;
`;
const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
  font-size: 0.8rem;
  text-align: center;
  color: white;
`;

const Grid = styled.div`
  display: grid;
  grid-template-rows: 1rem 2rem;
  grid-template-columns: 6fr 4fr;
`;
const GridItem = styled.div`
  font-size: 0.65rem;
  display: flex;
  justify-content: flex-end;

  &:nth-child(2n + 2) {
    justify-content: flex-end;
  }

  &:nth-child(2n + 1) {
    justify-content: flex-start;

    &::after {
      content: ' :';
      display: inline-block;
    }
  }
`;

interface IProps {
  country: string;
  regionName: string;
  tooltipInfo: IRow[];
  x: number;
  y: number;
  regionColor: string;
}
export default class extends React.Component<IProps> {
  render() {
    const {tooltipInfo, x, y, regionColor, regionName, country} = this.props;
    const cells = getRows(tooltipInfo, GridItem);

    const yPosition = y - tooltipVerticalOffset;
    const style = {
      top: `calc(${yPosition}px - ${arrowHeight}vw)`,
      left: `${x}px`,
    };
    const titleStyle = {
      backgroundColor: regionColor,
    };
    return (
      <Root style={style}>
        <RegionContainer style={titleStyle}>{regionName}</RegionContainer>
        <ContentContainer>
          <TitleContainer>{country}</TitleContainer>
          <Grid>
            {cells}
          </Grid>
        </ContentContainer>
      </Root>
    );
  }
}

import React from 'react';
import Overlay from '../../sharedComponents/DetailOverlay';
import {
  ICountryDatum,
} from '../../workerStore/geo/Utils';

interface IProps {
  detailed: number | undefined;
  countryData: Record<string, ICountryDatum>;
  hideOverlay: () => void;
}

export default class extends React.Component<IProps> {
  render() {
    const {detailed, countryData, hideOverlay} = this.props;
    let overlay: JSX.Element | null;
    if (detailed === undefined) {
      overlay = null;
    } else {
      const retrieved = countryData[detailed];
      if (retrieved === undefined) {
        overlay = null;
      } else {
        if (retrieved.isPrimaryCountry === true) {
          overlay = null;
        } else {
          const {detailOverlayInfo, color, longLabel} = retrieved;
          overlay = (
            <Overlay rows={detailOverlayInfo} color={color} title={longLabel}
              hideOverlay={hideOverlay}/>
          );
        }
      }
    }

    return (
      overlay
    );
  }
}
